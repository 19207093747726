import { Injectable } from '@angular/core';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { TAndCComponent, TAndCResponse } from './t-and-c.component';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';

import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { LoggerService } from '../logger/logger.service';
import { TimestampService } from '../timestamp/timestamp.service';
import { TranslateManagerService } from '../translate/translate-manager.service';
@Injectable()
export class TAndCService {

  constructor(
    private _tnDialogService: TnDialogService,
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _loggerService: LoggerService,
    private _timestampService: TimestampService,
    private _translateManagerService: TranslateManagerService,
  ) { }

  openTAndCDialog(isNeedAccept?: boolean, acceptCallback?: Function, declineCallback?: Function, tAndCData?: TAndCResponse): void {
    // check if enabled tnc first.
    if (!this._teamnoteConfigService.config.GENERAL.IS_ENABLE_TNC) {
      acceptCallback();
      return;
    }

    let dialogRef = this._tnDialogService.openTnDialog(TAndCComponent, {
      isNeedAccept: isNeedAccept,
      acceptCallback: acceptCallback,
      declineCallback: declineCallback,
      tAndCData: tAndCData
    });
    dialogRef.beforeClosed().subscribe(result => {

    });
  }

  checkTAndC(acceptCallback: Function, declineCallback: Function): void {
    // check if enabled tnc first.
    if (!this._teamnoteConfigService.config.GENERAL.IS_ENABLE_TNC) {
      acceptCallback();
      return;
    }
    
    this._loggerService.debug("Getting latest TNC...");

    let lastAcceptTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_PRESERVE_COOKIES.TNC_LAST_ACCEPT_TIME);
    let customLastUpdateTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_PRESERVE_COOKIES.TNC_CUSTOM_LAST_UPDATE_TIME);

    let noServerTnCCallback = () => {
      if (!lastAcceptTime) {
        // not accepted before
        this._loggerService.debug("TNC not accepted before");
        this.promptAcceptTAndC(acceptCallback, declineCallback);
        return;
      }
      if (customLastUpdateTime) {
        // it had custom tnc before, but now removed
        this._loggerService.debug("TNC accepted before, but now removed");
        this.promptAcceptTAndC(acceptCallback, declineCallback);
        return;
      }
      acceptCallback();
    };

    let url = TeamNoteApiConstant.GET_TNC;
    this._teamnoteApiService.callApi(
      url,
      {},
      (tncData: TAndCResponse) => {
        if (tncData && tncData.html_id) {
          if (this.checkIfTAndCUpdated(lastAcceptTime, tncData.update_date)) {
            // T&C is updated. ask user to accept again
            this._loggerService.debug("TNC is updated, prompt to accept again");
            this.promptAcceptTAndC(acceptCallback, declineCallback, tncData);
          } else {
            // T&C no update, continue.
            this._loggerService.debug("No updated TNC.");
            acceptCallback();
          }
        } else {
          // no server T&C
          this._loggerService.debug("No server TNC.");
          noServerTnCCallback();
        }
      },
      (err) => {
        this._loggerService.error("get_tnc error.");
        noServerTnCCallback();
      },
      false,
      false,
      false,
      false,
      this._translateManagerService.currentI18nKey
    );
  }

  checkIfTAndCUpdated(lastAcceptTime: string, newUpdateTime: string): boolean {
    this._loggerService.debug("Checking tnc update_date...");
    return this._timestampService.checkIfTimeCorrectOrder(lastAcceptTime, newUpdateTime);
  }

  promptAcceptTAndC(acceptCallback: Function, declineCallback: Function, tAndCData?: TAndCResponse): void {
    this.openTAndCDialog(
      true,
      acceptCallback,
      declineCallback,
      tAndCData
    );
  }

}
