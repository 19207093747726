<div class="chat-group-setting-modal">
  <div class="modal-header">
    <h4 class="text-color-primary">
      <ng-container *ngIf="currentAction == ACTION_CODE.VIEW_INFO">
        {{'WEBCLIENT.CHATROOM.SETTINGS' | translate}}
      </ng-container>
      <ng-container *ngIf="currentAction == ACTION_CODE.ADD_MEMBER">
        {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER' | translate}}
      </ng-container>
      <ng-container *ngIf="currentAction == ACTION_CODE.ADD_MEMBER_FROM_GROUP">
        {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER_FROM_CHAT_GROUP' | translate}}
      </ng-container>
      <ng-container *ngIf="currentAction == ACTION_CODE.ADD_MEMBER_FROM_BROADCAST">
        {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER_FROM_BROADCAST' | translate}}
      </ng-container>
      <ng-container *ngIf="currentAction == ACTION_CODE.REMOVE_MEMBER">
        {{'WEBCLIENT.CHAT.CHAT_GROUP.REMOVE_MEMBER' | translate}}
      </ng-container>
      <ng-container *ngIf="currentAction == ACTION_CODE.ADD_GROUP_ADMIN">
        {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_ADMIN' | translate}}
      </ng-container>
    </h4>
  </div>
  <div class="modal-body">
    <div class="chat-group-setting-container">
        <div class="half-col info-col">
          <div class="general-info" *ngIf="currentAction == ACTION_CODE.VIEW_INFO">
            <div class="image-upload-button">
              <label [ngClass]="{'clickable': isChatAdmin}">
                <input type="file" name="file" *ngIf="isChatAdmin" accept="image/*" (change)="handleFileInputChange($event)"/>
                <div class="avatar default-group-avatar" [ngStyle]="{'background-image': chat.picBgUrl}" *ngIf="isGroupChat"></div>
                <div class="avatar default-contact-avatar" [ngStyle]="{'background-image': chat.chatTarget.avatarImageSrc}" *ngIf="!isGroupChat"></div>
              </label>
            </div>
            <div class="group-name-input">
              <div *ngIf="!isEditingGroupName">
                <p class="form-control-static">
                  {{chat.name}} 
                  <i class="fa fa-fw fa-pencil text-color-primary" *ngIf="isChatAdmin" (click)="isEditingGroupName = true"></i>
                </p>
              </div>
              <div class="input-group" *ngIf="isEditingGroupName">
                  <input type="text" class="form-control" [(ngModel)]="groupName" [maxlength]="groupNameMax"/>
                  <span class="input-group-addon">({{groupName.length}}<span *ngIf="groupNameMax">/{{groupNameMax}}</span>)</span>
                  <span class="input-group-addon">
                    <i class="fa fa-fw fa-times text-red" (click)="isEditingGroupName = false"></i>
                    <i class="fa fa-fw fa-check text-color-primary" (click)="updateGroup()"></i>
                  </span>
              </div>
            </div> <!-- group-name-input -->

            <div class="actions-wrapper">
              <div class="action clickable" *ngIf="isChatAdmin && canManageMember" (click)="addMember()">
                {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER' | translate}}
              </div>
              <div class="action clickable" *ngIf="isChatAdmin && canManageMember" (click)="addMemberFromGroup()">
                {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER_FROM_CHAT_GROUP' | translate}}
              </div>
              <div class="action clickable" *ngIf="isChatAdmin && canManageMember && IS_ALLOW_BROADCAST" (click)="addMemberFromBroadcast()">
                {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER_FROM_BROADCAST' | translate}}
              </div>
              <div class="action notification-toggle-row" *ngIf="isAllowMute">
                <div class="notification-text">
                  <div class="mute-title">
                    {{'WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.TITLE' | translate}}
                  </div>
                  <div class="text-color-secondary" *ngIf="chat.isMuted">
                    {{'WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.UNTIL' | translate}}
                    <span *ngIf="chat.muteExpiredAt">{{chat.muteExpiredAt | tnDate: 'OUT_OF_OFFICE_UNTIL_DATE'}}</span>
                    <span *ngIf="!chat.muteExpiredAt">{{'WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.FOREVER' | translate}}</span>
                  </div>
                </div>

                <div class="notification-mute-button" (click)="toggleMute()">
                  <i class="fa fa-fw fa-2x" 
                    [ngClass]="{
                      'fa-toggle-on mute-on': chat.isMuted, 
                      'fa-toggle-off mute-off': !chat.isMuted, 
                      'clickable': isAllowMute
                    }"></i>
                </div>
              </div>
              <div class="leave-group" *ngIf="chat.can_leave && isGroupChat">
                <button class="btn btn-danger btn-block" (click)="leaveGroup()">
                  {{'WEBCLIENT.CHAT.CHAT_GROUP.LEAVE_GROUP' | translate}}
                </button>
              </div>
            </div> <!-- actions-wrapper -->
          </div> <!-- general-info -->
          <div class="selected-view" *ngIf="currentAction == ACTION_CODE.ADD_MEMBER || currentAction == ACTION_CODE.ADD_MEMBER_FROM_GROUP || currentAction == ACTION_CODE.ADD_MEMBER_FROM_BROADCAST">
            <div class="select-actions">
              <button class="btn btn-default" (click)="back()">{{'GENERAL.BACK' | translate}}</button>
              <button class="btn btn-danger" (click)="unSelectAllMembers()" [disabled]="selectedMembers.length == 0">{{'WEBCLIENT.CHAT.CHAT_GROUP.REMOVE_ALL' | translate}} ({{selectedMembers.length - currentMembers.length}})</button>
              <button class="btn tn-button-primary" (click)="selectConfirm()">{{'GENERAL.CONFIRM' | translate}}</button>
            </div>
            <div class="selection-list">
              <tn-contact-list 
                class="tn-contact-list-wrapper"
                [contacts]="selectedMembers"
                [excludingUsers]="currentMembers"
                [isShowSearchBar]="false"
                [noJumpToStart]="true"
                (contactCellOnClick)="unSelectMember($event)">
              </tn-contact-list>
            </div>
          </div>
        </div> <!-- half-col -->

        <div class="half-col member-col" *ngIf="isGroupChat">
          <div class="member-info-wrapper" *ngIf="currentAction == ACTION_CODE.VIEW_INFO">
            <div class="member-info" *ngFor="let m of currentMembers">
              <tn-contact-cell
                (contactOnClick)="openMemberContactCard(m)"
                [contact]="m">
                <div class="additional-left">
                  <div class="online-status" [ngClass]="{'online': m.is_online}"></div>
                </div>

                <div class="additional-right" *ngIf="checkShouldDisplayMemberExtra(m) || checkShouldDisplayMemberOption(m)">
                  <div class="member-extra-wrapper">
                    <div class="label-wrapper" *ngIf="checkShouldDisplayMemberExtra(m)">
                      <div class="admin-label" *ngIf="m.isChatAdmin">
                        <span>{{'WEBCLIENT.CHATROOM.GROUP_INFO_ITEM.ADMIN' | translate}}</span>
                      </div>

                      <div class="out-of-office-label text-color-secondary" *ngIf="m.isOutOfOffice">
                        <span>{{'WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.TITLE' | translate}}</span>
                      </div>
                    </div>

                    <div class="member-option" 
                      *ngIf="checkShouldDisplayMemberOption(m)">
                      <div class="btn-group tn-dropdown-group" 
                        dropdown placement="bottom right">
                        <button 
                          dropdownToggle 
                          (click)="onMemberDropdownClick($event)"
                          type="button" 
                          class="option-button dropdown-toggle tn-dropdown-button">
                          <i class="fa fa-angle-down fa-fw"></i>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                          <li role="menuitem" *ngIf="canManageMember">
                            <div class="tn-dropdown-item" 
                            (click)="submitRemoveMember(m.user_id)">
                              {{'WEBCLIENT.CHAT.CHAT_GROUP.REMOVE_MEMBER' | translate}}
                            </div>
                          </li>
                          <li role="menuitem" *ngIf="canManageAdmin">
                            <div class="tn-dropdown-item" 
                              (click)="submitAddGroupAdmin(m.user_id)" 
                              *ngIf="!m.isChatAdmin">
                              {{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_ADMIN' | translate}}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </tn-contact-cell>

            </div>
          </div> <!-- member-info-wrapper -->
          <div class="unselected-view" *ngIf="currentAction != ACTION_CODE.VIEW_INFO">

            <tn-contact-list-container 
              class="member-picker"
              *ngIf="currentAction != ACTION_CODE.ADD_MEMBER_FROM_GROUP && currentAction != ACTION_CODE.ADD_MEMBER_FROM_BROADCAST"
              [noJumpToStart]="true"
              [excludingUsers]="selectedMembers"
              [isOverrideContactCellOnClick]="true"
              [isAllowSelectAll]="true"
              [isEnableLimitNoDeviceUserGroupChat]="isEnableLimitNoDeviceUserGroupChat"
              (selectAllOnClickEvent)="selectMembers($event)"
              (contactCellOnClickEvent)="selectMember($event)">
            </tn-contact-list-container>

            <ng-container *ngIf="currentAction == ACTION_CODE.ADD_MEMBER_FROM_GROUP">
              <tn-search-bar 
                class="tn-search-bar"
                (onKeywordChanged)="updateUnselectedList($event)">
              </tn-search-bar>
              <div class="selection-list">
                <div *ngIf="currentAction == ACTION_CODE.ADD_MEMBER_FROM_GROUP">
                  <div *ngFor="let c of filteredChatGroups">
                    <tn-chat-cell 
                      [chat]="c" 
                      (chatOnClick)="chatGroupOnClick($event)">
                    </tn-chat-cell>
                  </div>
                </div>
              </div> <!-- selection-list -->

            </ng-container>

            <ng-container *ngIf="currentAction == ACTION_CODE.ADD_MEMBER_FROM_BROADCAST">
              <tn-contact-list
                class="tn-contact-list-wrapper"
                [userGroups]="broadcastGroups"
                [isShowSearchBar]="true"
                [allowSearchUserGroup]="true"
                [noJumpToStart]="true"
                (userGroupCellOnClick)="selectBroadcastGroup($event)">
              </tn-contact-list>
            </ng-container>
          </div>
        </div> <!-- half-col -->
    </div>
  </div> <!-- modal-body -->
</div>