<form>
  <section class="form-block">
    <ng-container *ngIf="isDomainInput">
      <div class="login-body-row input-action-msg">
        {{'LOGIN.GET_STATE.DOMAIN.INPUT_TIP' | translate}}
      </div>
      <div class="login-body-row">
        <input 
          #domainInput
          class="form-control" 
          type="text" 
          [(ngModel)]="domain" 
          name="domain"
          placeholder="{{'LOGIN.GET_STATE.DOMAIN.DOMAIN' | translate}}" 
          size="30"
          autocomplete="off">
      </div>
      <div class="login-body-row">
        <button class="btn tn-button-primary btn-block" (click)="submitDomain()">
            {{'LOGIN.GET_STATE.SUBMIT' | translate}}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!isDomainInput">
      <ng-container *ngIf="isAllowTeamNoteLogin">
        <div class="login-body-row input-action-msg">
          <span *ngIf="isUseMobileLogin == '1'">
            {{'LOGIN.GET_STATE.MOBILE.INPUT_TIP' | translate}}
          </span>
          <span *ngIf="isUseMobileLogin == '0'">
            {{'LOGIN.GET_STATE.USERNAME.INPUT_TIP' | translate}}
          </span>
        </div>
        <div class="login-body-row mobile-selection-row" *ngIf="isDefaultUseMobile">
          <label>
            <input type="radio" [(ngModel)]="isUseMobileLogin" (click)="changeInputMode('1')" name="isUseMobileLogin" value="1"/>
            {{'LOGIN.GET_STATE.MOBILE.PHONE_NUMBER' | translate}}
          </label>
          <label>
            <input type="radio" [(ngModel)]="isUseMobileLogin" (click)="changeInputMode('0')" name="isUseMobileLogin" value="0"/>
            {{'LOGIN.GET_STATE.USERNAME.USERNAME' | translate}}
          </label>
        </div>
        <div class="login-body-row mobile-input-wrapper" *ngIf="isUseMobileLogin == '1'">
          <input 
            #countryCodeInput
            class="form-control country-code" 
            type="text" 
            [(ngModel)]="countryCode" 
            name="countryCode" 
            placeholder="852" 
            size="10"
            autocomplete="off">
          <input 
            #phoneNumInput
            class="form-control phone-num" 
            type="text" 
            [(ngModel)]="userName" 
            name="userName" 
            placeholder="12345678" 
            size="20"
            autocomplete="off">
        </div>
        <div class="login-body-row" *ngIf="isUseMobileLogin == '0'">
          <input 
            #userNameInput
            class="form-control" 
            type="text" 
            [(ngModel)]="userName" 
            name="userName" 
            placeholder="{{'LOGIN.GET_STATE.USERNAME.USERNAME' | translate}}" 
            size="30"
            autocomplete="off">
        </div>
        <div class="login-body-row">
          <button class="btn tn-button-primary btn-block" (click)="submit()">
              {{'LOGIN.GET_STATE.SUBMIT' | translate}}
          </button>
        </div>
        <div class="login-body-row" *ngIf="(domain || isAllowDomainInput) && isShowDomainInput">
          <button class="btn tn-button-primary btn-block" (click)="showDomainInput()">
              {{'GENERAL.BACK' | translate}}
          </button>
        </div>
      </ng-container>
      
      <div class="login-body-row" *ngIf="isQRCodeLogin">
        <div *ngIf="qrCodeFailCount < qrCodeFailThreshold">
          <qrcode [qrdata]="qrCodeString" [width]="250"></qrcode>
          <div class="qrcode-tip" [innerHTML]="'LOGIN.GET_STATE.QR_CODE.TIP' | translate"></div>
          <!-- <div>
            To use BOCHK EIM on your computer:
            <ol style="text-align: left;">
              <li>Open BOCHK EIM on your phone</li>
              <li>(Android) Tap More... → Login web client<br>
                (iOS) Tap More... → About → Login web client</li>
              <li>Point your phone to this screen to capture the QR code</li>
            </ol>
          </div> -->
        </div>
        <div *ngIf="qrCodeFailCount >= qrCodeFailThreshold">
          <button class="btn tn-button-primary btn-block" (click)="startQRCodeHandling()">
            {{'LOGIN.GET_STATE.QR_CODE.BUTTON' | translate}}
          </button>
        </div>
      </div>

      <tn-login-methods
        [loginMethods]="loginMethods"
        [isAllowTeamNoteLogin]="isAllowTeamNoteLogin"
        (handleExternalAuthLogin)="handleExternalAuthLogin($event)">
      </tn-login-methods>
    </ng-container>
  </section>
</form>
