import { TeamNoteWebClientConfig } from './models/webclient-config';
import { TeamNotePasswordPolicyConstant } from '../constants/password-policy.constant';
import { PageUrlConstant } from '../constants/page-url.constant';
import { TeamNoteConfig } from './models/config';
import { ModuleKeyDefinition } from '../constants/module.constant';
import { VersionConfig } from './version.config';

import union from 'lodash/union';
import cloneDeep from 'lodash/cloneDeep';
import { AttachmentTypeConstant } from '../constants/attachment-type.constant';

export const TeamNoteDefaultConfig: TeamNoteConfig = {
  HOST: {
    API_HOST: '',
    DOMAIN: null,
    WEB_CLIENT_SSL: true,
    WEB_CLIENT_PORT: 443
  },
  VERSION: VersionConfig,
  MANIFEST: {
    PATH: ''
  },
  WEB_DAV: {
    HOST : 'https://cloud.dev.teamnoteapp.com',
    BASEPATH: 'webdav',
    USER_NAME: 'user1',
    PASSWORD: 'aaaaaaaA1'
  },
  GENERAL: {
    PAGE_TITLE: 'TeamNote Web Client',
    TITLE_LABEL: '',
    SYSTEM_LABEL: '',
    SITE_LABEL: '',
    BUILD_LABEL: '',
    NAV_LABEL: '',
    NAV_UAT_LABEL: '',
    DEFAULT_COUNTRY_CODE: '852',
    CHAT_GROUP_NAME_MAX_LENGTH: 50,
    ATTACHMENT_SIZE_LIMIT: 25,
    VIDEO_SIZE_LIMIT: 25,
    IMAGE_COMPRESSION: {
      MIME_TYPE: 'image/jpeg',
      QUALITY_RATIO: 0.6,
      MAX_WIDTH_PIXEL: 1800,
      MAX_HEIGHT_PIXEL: 1800
    },
    IS_ENABLE_DEBUG: false,
    VERSION_CHECK_INTERVAL_MIN: 60,
    IS_LOGIN_MODE_MOBILE: false,
    PASSWORD_POLICY_REGEX: TeamNotePasswordPolicyConstant.NONE,
    PASSWORD_POLICY_LENGTH: 8,
    IS_RESTRICTED_ATTACHMENT: false,
    IS_SUPPORT_PASSWORD_PDF: false,
    TNC_CHECK_INTERVAL_MIN: 15,
    IS_ENABLE_TNC: true,
    IS_RESTRICT_CLIPBOARD: false,
    IS_UAT: false,
    IS_CUSTOM_DOAMIN: false,
    IS_SHOW_DOAMIN_INPUT: true,
    ELECTRON_HIDE_LOGOUT_BTN: false,
    DOWNLOAD_FILE_WITH_PREFIX: true
  },

  LANGUAGE: {
    DEFAULT: "en",
    EXCLUSION: [], // 'en', 'zh-cht', 'zh-chs'
  },

  LOGIN: {
    PWA_LOGIN_REDIRECT_URL: '',
    PWA_LOGIN_REDIRECT_IPS: []
  },

  MAP: {
    API_KEY: 'AIzaSyDvi1qyMJetX6sHaDftjRcaPYVwr1tKzxw',
    DEFAULT_LOCATION: {
        LAT: 22.331893357520617,
        LNG: 114.12129778808594
    },
    DEFAULT_ZOOM_LEVEL: 11,
    STATIC_MAP: {
      PATH_PREFIX: 'https://maps.googleapis.com/maps/api/staticmap',
      SIZE: '100x100',
      ZOOM_LEVEL: '15'
    }
  },
  IMAGES: {
    NOTIFICATION_ICON: 'assets/images/general/notification_icon.png',
    FAVICON: 'assets/images/general/favicon.png',
    FAVICON_ALERT: 'assets/images/general/favicon_alert.png'
  },

  NOTIFICATION: {
    ENABLE_NOTIFICATION_CENTER: true,
    PLAY_NOTIFICATION_AUDIO: false,
    NOTIFICATION_AUDIO_NORMAL: 'assets/audios/notification_normal.wav',
    NOTIFICATION_AUDIO_IMPORTANT: 'assets/audios/notification_important.wav'
  },

  WEBCLIENT: {
    GENERAL: {
      MESSAGE_CHUNK_SIZE: 50,
      WEBCLIENT_DEFAULT_PAGE: PageUrlConstant.WEBCLIENT.CHAT,
      WEBCLIENT_DEFAULT_PAGE_KEY: ModuleKeyDefinition.CHAT,
      IS_USE_WEBSOCKET: true,
      IS_ALLOW_SOCKJS_FALLBACK: true,
      TERMS_AND_CONDITION_FILE_DEFAULT: 'assets/tandc/general/tandc.html',
      TERMS_AND_CONDITION_FILE_PATH: {
        'zh-cht': 'assets/tandc/general/tandc_tc.html',
        'zh-chs': 'assets/tandc/general/tandc_sc.html'
      },
      IS_REMOVE_SESSION_USER_NAME: false,
      FORCE_LOGIN_NEW_TAB: false,
      IS_ALLOW_AUTO_EXTEND_SESSION: false,
      IS_ENABLE_CHAT_LIST_MESSAGE_SEARCH: false
    },
    CONTACT: {
      LIST_DESC_FIELDS: [
        'title',
        'department',
        'public_message'
      ],
      CARD_DESC_FIELDS: [
        'title',
        'department',
        'email',
        'mobile',
        'public_message'
      ],
      FORCE_CONTACT_ACTIONS: false,
      IS_ENABLE_INACTIVE_USER_CHECK: false
    },
    CHATROOM: {
      SHIFT_ENTER_TO_SEND: false,
      ENABLE_ENCRYPTED_MESSAGE: false,
      CONFIDENTIAL_CHAT_IDLE_TIME_SECOND: 60,
      ENABLE_HTML_MESSAGE: false,
      ENABLE_EXPORT_CHAT: false,
      IS_ALLOW_ALL_FILE_TYPE: false,
      VALID_FILE_TYPES: JSON.stringify(union(
        AttachmentTypeConstant.IMAGE_TYPES,
        AttachmentTypeConstant.VIDEO_TYPES,
        AttachmentTypeConstant.DOC_TYPES,
        AttachmentTypeConstant.PDF_TYPES
      )),
      VALID_FILE_TYPES_DISPLAY: JSON.stringify(union(
        AttachmentTypeConstant.IMAGE_TYPES_DISPLAY,
        AttachmentTypeConstant.VIDEO_TYPES_DISPLAY,
        AttachmentTypeConstant.DOC_TYPES_DISPLAY,
        AttachmentTypeConstant.PDF_TYPES_DISPLAY
      )),
      ENABLE_WHISPER: false,
      MUTE: {
        ONE_HOUR: false,
        TWO_HOURS: false,
        EIGHT_HOURS: true,
        ONE_WEEK: true,
        ONE_YEAR: false
      },
      AUDIO_MESSAGE_PLAY_TO_READ: false,
      IS_ENABLE_MENTION: false,
      IS_ENABLE_HASHTAG: false,
      IS_ENABLE_SEARCH: false,
      IS_SHOW_MESSAGE_ID: false,
      IS_FLASH_ANIMATION: false,
      IS_ENABLE_ATTACH: false,
      IS_ENABLE_IMPORTANT: false,
      IS_ENABLE_SMS: false,
      INPUT_TEXTAREA_MAX_HEIGHT: 60,
      IS_AUTO_ADJUST_HEIGHT: true,
      IS_ACK_TO_READ: false,
      IS_ENABLE_MESSAGE_ACK: false,
      IS_DISABLE_ENCRYPTED_MSG_DOWNLOAD: false,
      IS_SHOW_ACK_INFO: false,
      IS_SHOW_UNREAD_INFO: false,
      IS_ENABLE_ATTACH_CORPORATE_MATERIAL: false,
      IS_ENABLE_ATTACH_WEB_DAV_MATERIAL: false,
      IS_DISABLE_LOCATION_UPLOAD: false,
    },
    NEWS: {
      IS_ENABLE_CONTENT_PREVIEW: false
    },
    AMQP: {
      CHAT_LOAD_HISTORY_SIZE: 40,
      LOAD_RECENT_MESSAGE_SIZE: 20,
      NEWS_LOAD_HISTORY_SIZE: 20
    },
    SIDE_NAV: {
      SIDE_NAV_ORDERING: null,
      SIDE_NAV_EXTRA_MODULES: [],
      SIDE_NAV_UNREAD_COUNTS_MODULES: [
        ModuleKeyDefinition.CHAT,
        ModuleKeyDefinition.NEWS
      ]
    },
    WATERMARK: {
      WATERMARK_SHOW_LOGIN_NAME: true,
      IS_SHOW_OPEN_TIME: false,
      IS_NEED_DATE_TIME: false,
      IS_DRAW_ON_IMAGE: false,
      DEFAULT_WATERMARK: null
    },
    FORMS: {
      IS_ALLOW_EXPORT_FORMS: false
    },
    DATES: {
      IS_24_HOURS_FORMAT: false
    },
    CORPORATE_MATERIAL: {
      IS_GRID_VIEW: false,
      IS_ALLOW_CHANGE_VIEW_MODE: false
    },
    WEB_DAV_MATERIAL: {
      IS_GRID_VIEW: false,
      IS_ALLOW_CHANGE_VIEW_MODE: false
    }
  },

  UI: {
    USE_NEW_LOGIN_PAGE_UI: false
  }
};
