import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
// Services
import { WebclientLoginService } from './webclient-login.service';

// Routing
import { LoginRoutingModule } from './login-routing.module';

// Components
import { LoginComponent } from './login.component';
import { GetStateComponent } from './get-state/get-state.component';
import { OtpComponent } from './otp/otp.component';
import { PasswordComponent } from './password/password.component';
import { SecondFactorComponent } from './second-factor/second-factor.component';
import { SetPasswordComponent } from './set-password/set-password.component';

import { TranslateModule } from '@ngx-translate/core';
import { OauthService } from './oauth.service';
import { LoginMethodsComponent } from './login-methods/login-methods.component';
import { SamlService } from './saml.service';
import { UtilitiesModule } from '../utilities/utilities.module';

@NgModule({
  declarations: [
      LoginComponent,
      GetStateComponent,
      OtpComponent,
      PasswordComponent,
      SecondFactorComponent,
      SetPasswordComponent,
      LoginMethodsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoginRoutingModule,

    TranslateModule,
    QRCodeModule,
    UtilitiesModule
  ],
  providers: [
    WebclientLoginService,
    OauthService,
    SamlService
  ]
})
export class LoginModule { }
