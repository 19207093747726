import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserContact } from '../../../models/user-contact';
import { UserGroup } from '../../../models/user-group';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { UserGroupService } from '../../services/data/user-group/user-group.service';
import { DataManagerService } from '../../services/data/data-manager.service';
import { ContactService } from '../contact.service';
import { LoggerService } from '../../../utilities/logger/logger.service';

import * as _ from 'lodash';
import { ContactCardService } from '../contact-card/contact-card.service';
import { TranslateManagerService } from '../../../utilities/translate/translate-manager.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { WebclientService } from '../../webclient.service';
import { AccountManagerService } from '../../services/account/account-manager.service';

@Component({
  selector: 'tn-contact-list-container',
  templateUrl: './contact-list-container.component.html',
  styleUrls: ['./contact-list-container.component.scss']
})
export class ContactListContainerComponent implements OnInit {
  @Input() isShowSearchBar: boolean = true;
  @Input() noJumpToStart: boolean = false;
  @Input() excludingUsers: UserContact[] = [];
  @Input() isOverrideContactCellOnClick: boolean = false;
  @Input() isAllowSelectAll: boolean = false;
  @Input() excludingSelf: boolean = false;
  @Input() isEnableLimitNoDeviceUserGroupChat: boolean = false;
  @Output() contactCellOnClickEvent = new EventEmitter<UserContact>()
  @Output() selectAllOnClickEvent = new EventEmitter<UserContact[]>()

  title: string = 'WEBCLIENT.CONTACTS';

  userGroupIdStack: string[] = [];
  isInsideUserGroup: boolean = false;
  currentUserGroup: UserGroup = null;

  contacts: UserContact[];
  userGroups: UserGroup[];

  isEnableWarnNoDeviceUserGroupChat: boolean = false;
  // isEnableLimitNoDeviceUserGroupChat: boolean = false;

  constructor(
    private _userContactService: UserContactService,
    private _userGroupService: UserGroupService,
    private _dataManagerService: DataManagerService,
    private _contactService: ContactService,
    private _loggerService: LoggerService,
    private _contactCardService: ContactCardService,
    private _translateManagerService: TranslateManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _webClientService: WebclientService,
    private _accountManagerService: AccountManagerService,
  ) { 
    this._loggerService.debug("Subscribing to userContacts & userGroups: update contact list");
    this._userContactService.userContacts$.subscribe(contacts => {
      this.getContactListUnderUserGroup();
    });
    this._userGroupService.userGroups$.subscribe(userGroups => {
      this.getContactListUnderUserGroup();
    });
    this._translateManagerService.currentTranslation$.subscribe(lang => {
      this.updateTitle();
    });
  }

  ngOnInit() {
    this._loggerService.debug("Contact ngOnInit");
    this.getContactListUnderUserGroup();
    this._userContactService.updateUserGroupIdStackMetaData();

  }

  ngOnChanges() {
    this.getContactListUnderUserGroup();
  }

  ngOnDestroy() {
    this._userContactService.initUserGroupIdStack();
    // this._userContactService.clearUserGroupIdStackMetaData();
  }

  getContactListUnderUserGroup(): void {
    this.isEnableWarnNoDeviceUserGroupChat = this._webClientService.warn_no_device_user_group_chat;
    // this.isEnableLimitNoDeviceUserGroupChat = this._webClientService.limit_no_device_user_group_chat;

    let topUserGroupId = _.last(this._userContactService.userGroupIdStack);
    this.currentUserGroup = this._dataManagerService.getUserContactsUnderUserGroup(topUserGroupId);
    this.contacts = this.currentUserGroup ? this.currentUserGroup.contactsWithoutSelf : [];
    // Exclude users
    this.contacts = _.difference(this.contacts, this.excludingUsers);

    if (this.isEnableLimitNoDeviceUserGroupChat) {
      this.contacts = this.filterOutNoDeviceContacts();
    }

    if (!this._webClientService.allow_self_message) {
      this.contacts = _.filter(this.contacts, (c) => c.user_id !== this._accountManagerService.userId)
    } else {
      if (this.excludingSelf) {
        this.contacts = _.filter(this.contacts, (c) => c.user_id !== this._accountManagerService.userId)
      }
    }

    this.userGroups = this.currentUserGroup ? this.currentUserGroup.childUserGroup : [];

    if (this._userContactService.userGroupIdStack.length == 1) {
      this.title = null;
      this.isInsideUserGroup = false;
    } else {
      this.title = this.currentUserGroup ? this.currentUserGroup.name : null;
      this.isInsideUserGroup = true;
    }
    this.updateTitle();
  }

  filterOutNoDeviceContacts(): UserContact[] {
    return _.filter(this.contacts, (c) => c?.device_count > 0);
  }

  updateTitle(): void {
    if(this.isInsideUserGroup) {
      if (this.currentUserGroup._i18n) {
        let i18n = this.currentUserGroup._i18n[this._translateManagerService.getCurrentLangI18nKey()];
        if (i18n) {
          this.title = i18n.name;
        }
      }
    }
  }

  contactCellOnClick(contact: UserContact): void {
    this._loggerService.log("Clicked on contact cell: " + contact.user_id + contact.name);
    if (this.isOverrideContactCellOnClick) {
      this.contactCellOnClickEvent.emit(contact);
      return;
    }
    this._contactCardService.openContactCardModal(contact, false, null, true, this.isEnableWarnNoDeviceUserGroupChat);
    // this._contactService.contactCellOnClick(contact);
  }

  userGroupCellOnClick(userGroup: UserGroup): void {
    this._loggerService.log("Clicked on user group cell: " + userGroup.user_group_id + userGroup.name);
    this._userContactService.addUserGroupIdToStack(userGroup.user_group_id);
    this.getContactListUnderUserGroup();
  }
  userGroupBack(): void {
    this._loggerService.log("Clicked on contact list back button");
    this._userContactService.popUserGroupIdStack();
    this.getContactListUnderUserGroup();
  }

  selectAllOnClick(): void {
    this.selectAllOnClickEvent.emit(this.contacts);
  }

}
