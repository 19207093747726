import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LanguageConstant } from '../../constants/language.constant';
import { TranslateManagerService } from '../../utilities/translate/translate-manager.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

@Component({
  selector: 'tn-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  languageOptions: any[] = [];
  excludedLangKeys: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<LanguageComponent>,
    private _translateManagerService: TranslateManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
  ) { 
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.excludedLangKeys = this._teamnoteConfigService.config.LANGUAGE.EXCLUSION;
    });
  }

  ngOnInit() {
    this.dialogRef.updateSize('300px');

    this.languageOptions = _.toArray(LanguageConstant);
    this.languageOptions = _.filter(this.languageOptions, (lang) => !_.includes(this.excludedLangKeys, lang.key));
  }

  changeLang(key) {
    this._translateManagerService.changeLang(key);
    this.dialogRef.close();
  }

}
