import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { MessageAttachmentBody } from '../../../../../models/message-attachment-body';
import { Attachment } from '../../../../../models/attachment';
import { AttachmentTypeConstant } from '../../../../../constants/attachment-type.constant';
import { FileManagerService } from '../../../../../utilities/file-manager/file-manager.service';
import { AudioPlayerService, TnPlayingAudio } from '../../../../../utilities/audio-player/audio-player.service';
import { Subscription } from 'rxjs';
import { ChatRoomService } from '../../chat-room.service';
import * as _ from 'lodash';

@Component({
  selector: 'tn-attachment-message',
  templateUrl: './attachment-message.component.html',
  styleUrls: ['./attachment-message.component.scss']
})
export class AttachmentMessageComponent implements OnInit {
  @Input() isSentByMe: boolean;
  @Input() body: any;
  @Input() attachments: Attachment[];
  @Input() isClickable: boolean;
  @Input() messageId: string;

  @Output() onAttachmentClick: EventEmitter<Attachment> = new EventEmitter<Attachment>(null);

  attachment: Attachment;

  ATTACHMENT_TYPE = AttachmentTypeConstant;
  type: number;

  // Image & Video
  imgSrc: string;
  
  // Audio
  isPlayingAudio: boolean = false;
  audioDuration: string;
  currentAudio: TnPlayingAudio = {};

  // PDF
  filenameDisplay: string;
  fileSize: string;

  audioSub: Subscription = null;

  constructor(
    private _fileManagerService: FileManagerService,
    private _audioPlayerService: AudioPlayerService,
    private _chatRoomService: ChatRoomService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.audioSub = this._audioPlayerService.currentlyPlayingAudio$.subscribe(
      audio => {
        this.currentAudio = audio;
        this.parseAudio();
      }
    );
  }

  ngOnChanges() {
    if (!this.attachments || this.attachments.length == 0) {
      return;
    }
    this.attachment = this.attachments[0];
    let aid = this.attachment.attachment_id;
    this.type = this._fileManagerService.getAttachmentType(aid);
    switch (this.type) {
      case this.ATTACHMENT_TYPE.IMAGE:
        this.parseImg();
        break;
      case this.ATTACHMENT_TYPE.AUDIO:
        this.parseAudio();
        break;
      case this.ATTACHMENT_TYPE.VIDEO:
        this.parseVideo();
        break;
      default:
        this.parseGeneralFile();
        break;
    }
  }

  ngOnDestroy() {
    this.audioSub.unsubscribe();
  }

  parseImg() {
    if (this._fileManagerService.checkIfAttachmentIsGif(this.attachment.filename)) {
      this.imgSrc = this._fileManagerService.getBackgroundImgSrcByAttachmentId(this.attachment.attachment_id);
      return
    }
    this.imgSrc = this._fileManagerService.getBackgroundImgSrcByBase64(this.attachment.preview_image, this.attachment.content_type);
  }

  parseVideo() {
    this.imgSrc = this._fileManagerService.getBackgroundImgSrcByBase64(this.attachment.preview_image, this.attachment.content_type);
  }

  parseGeneralFile() {
    this.filenameDisplay = this.body.filename || this.attachment.filename;
    this.fileSize = this._fileManagerService.getFileSize(this.attachment.size);
  }

  onClick(event: any, isRealClick: boolean) {
    if (isRealClick) {
      switch (this.type) {
        case this.ATTACHMENT_TYPE.AUDIO:
          this.playAudioMessage();
          break;
        default:
          this.onAttachmentClick.emit(this.attachment);
          break;
      }
    } else {
      // Prevent parent div click event
      event.stopPropagation();
    }

  }

  onAudioClick(event: any) {
    this.onAttachmentClick.emit(this.attachment);
  }

  // Audio

  parseAudio() {
    if (!this.currentAudio) {
      this.setAudioToPause();
    } else {
      if (this.attachment) {
        if (this.currentAudio.attachmentId == this.attachment.attachment_id) {
          this.isPlayingAudio = true;
          if (this.currentAudio.currentDuration) {
            let remaining = this.attachment.duration - this.currentAudio.currentDuration * 1000;
            remaining = Math.max(remaining, 0);
            this.audioDuration = this._fileManagerService.getAudioDuration(remaining);
          }
        } else {
          this.setAudioToPause();
        }
      }
    }
    this._changeDetectorRef.detectChanges();
  }

  setAudioToPause(): void {
    this.isPlayingAudio = false;
    if (this.attachment && this.attachment.duration) {
      this.audioDuration = this._fileManagerService.getAudioDuration(this.attachment.duration);
    }
  }

  playAudioMessage(): void {
    if (this.isPlayingAudio) {
      this._audioPlayerService.stopAllAudioMessage();
    } else {
      this._audioPlayerService.playAudioMessageInChatRoomByAttachmentId(this.attachment.attachment_id);

      // send read for audio message
      this._chatRoomService.sendReadForAudioMessage(this.messageId);
    }
  }

  get getDocFileType(): number {
    if (!this.attachment) {
      return;
    }

    const split = this.attachment.attachment_id.split('.');
    const ext = _.last(split).toLowerCase();

    if (_.includes(AttachmentTypeConstant.DOC_TYPE, ext)) {
      return AttachmentTypeConstant.DOC;
    }
    if (_.includes(AttachmentTypeConstant.EXCEL_TYPE, ext)) {
      return AttachmentTypeConstant.SHEET;
    }
    if (_.includes(AttachmentTypeConstant.PPT_TYPE, ext)) {
      return AttachmentTypeConstant.PPT;
    }
    if (_.includes(AttachmentTypeConstant.PDF_TYPES, ext)) {
      return AttachmentTypeConstant.PDF;
    }
    return AttachmentTypeConstant.GENERAL;
  }
}
