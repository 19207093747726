export const ZhChsChatStrings = {
  HEADER_ANNOUNCEMENT: "公告",
  HEADER_GROUP: "群组",
  HEADER_INDIVIDUAL: "联络人",
  HEADER_FAVORITE: "最爱",
  HEADER_MESSAGE: "讯息",

  ADD_FAVORITE: "加到最爱",
  REMOTE_FAVORITE: "从最爱中移除",
  SEE_MORE: "查看更多",

  CONFIRM_FORWARD: "确定转发讯息到 {{USER_NAME}}?",

  RECEIVED_FILE: "收到档案",
  SENT_FILE: "发送了档案",
  RECEIVED_STICKER: "{{SENDER}} 传送了贴图",
  SENT_STICKER: "贴图已传送",
  REMOVE: "删除",

  NEW_GROUP: "建立群组",
  BROADCAST: "广播",
  SEARCH_MESSAGE: "搜寻讯息",
  SORT_TIME: "依时间排序",
  SORT_GROUP: "依群组排序",
  SEARCH_UNFILTERED: "筛选",
  SEARCH_FILTERED_ONE: "已筛选1名用户",
  SEARCH_FILTERED_MANY: "已筛选{{COUNT}}名用户",

  RESTRICTED_CHAT_OPEN_IN_APP_MSG: "基于安全原因，此聊天室只能在App中打开。",
  CONFIDENTIAL_MSG_PREVIEW: "**机密讯息**",

  ENCRYPTED_MSG_PREVIEW: "**安全讯息**",
  
  CHAT_GROUP: {
    GROUP_NAME: "群组名称",
    ADD_MEMBER: "新增群组成员",
    ADD_MEMBER_FROM_CHAT_GROUP: "从其它群组新增成员",
    ADD_MEMBER_FROM_BROADCAST: "从广播列表新增成员",
    NO_MEMBER: "没有群组成员",
    MEMBER_COUNT: {
      ONE: "{{NUM}}位群组成员",
      MULTIPLE: "{{NUM}}位群组成员"
    },
    NORMAL_GROUP: "一般群组",
    ANNOUNCEMENT_GROUP: "公告群组",
    GROUP_TYPE: "群组类型",
    GORUP_LEVEL: "讯息分级",

    ERROR_MSG: {
      NO_GROUP_NAME: "请输入群组名称",
      NO_MEMBER: "请选择群组成员"
    },

    ASSIGN_ADMIN: "指派群组管理员",
    ADD_ADMIN: "新增群组管理员",
    REMOVE_MEMBER: "移除群组成员",
    REMOVE_MEMBER_CONFIRM: "确认要将 {{USER}} 从群组中移除?",
    NOTIFICATION: {
      TITLE: "静音通知",
      ONE_HOUR: "1 小时",
      TWO_HOURS: "2 小时",
      EIGHT_HOURS: "8 小时",
      ONE_WEEK: "1 星期",
      ONE_YEAR: "1 年",
      FOREVER: "永久",
      UNTIL: "直到 "
    },
    LEAVE_GROUP: "离开群组",
    LEAVE_GROUP_CONFIRM: "确定要离开此群组?",
    DELETE_GROUP: "删除群组",
    DELETE_GROUP_CONFIRM: "确定要删除群组?",
    REMOVE_ALL: "删除所有已选择的成员"
  },

  CONTACT_CARD: {
    WHISPER: "私讯",
    CHAT: "聊天",
    IMPORTANT_USER: {
      SET: "重要用户",
      UNSET: "非重要用户"
    }
  }
};
