<vg-player style="height: 50px; width: 50%;" *ngIf="audioUrl">

  <vg-controls>
    <vg-play-pause></vg-play-pause>

    <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
    </vg-scrub-bar>
    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

  </vg-controls>


  <audio [vgMedia]="media" #media id="tn-audio-player" preload="auto" crossorigin>
    <source [src]="audioUrl" [type]="mimeType">
  </audio>
</vg-player>