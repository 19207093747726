import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';

import {WebclientLoginService} from '../webclient-login.service';
import {InputValidationService} from '../../utilities/input-validation/input-validation.service';

import {LoginComponent} from '../login.component';

import {CustomLoginResponse} from '../models/custom-login-response';
import {LOGIN_ACTIONS} from '../constants/login-actions.constant';
import {TnNotificationService} from '../../utilities/tn-notification/tn-notification.service';
import {TeamnoteConfigService} from '../../configs/teamnote-config.service';
import {LocalStorageManagerService} from '../../utilities/local-storage/local-storage-manager.service';
import {TeamNoteLocalStorageKeyConstants} from '../../constants/local-storage-key.constant';
import {LoginMethod} from '../models/login-methods-response';
import {LoginMethodConstant} from '../constants/login-methods.constant';
import {OAuthMeResponse} from '../models/oauth-me-response';
import {SamlMeResponse} from '../models/saml-me-response';
import {OauthService} from '../oauth.service';
import {SamlService} from '../saml.service';
import {GetStateResponse} from '../models/get-state-response';

import * as _ from 'lodash';
import {TAndCService} from '../../utilities/t-and-c/t-and-c.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BaseRoutingService} from '../../routing/base-routing.service';
import {ActivatedRoute, ParamMap} from '@angular/router';

@Component({
  selector: 'tn-get-state',
  templateUrl: './get-state.component.html',
  styleUrls: ['./get-state.component.scss']
})
export class GetStateComponent implements OnInit {
  // Login Params
  domain: string;
  countryCode: string;
  userName: string;
  @ViewChild('domainInput', {static: false}) domainInput: ElementRef;
  @ViewChild('countryCodeInput', {static: false}) countryCodeInput: ElementRef;
  @ViewChild('phoneNumInput', {static: false}) phoneNumInput: ElementRef;
  @ViewChild('userNameInput', {static: false}) userNameInput: ElementRef;

  // Local Controls
  isAllowDomainInput = false;
  isShowDomainInput = false;
  isDomainInput = false;
  isUseMobileLogin = '0';
  passwordConfirm: string;
  isDefaultUseMobile = false;

  errorMsg: string;

  isAllowTeamNoteLogin = false;
  loginMethods: LoginMethod[] = [];
  isQRCodeLogin = false;
  sessionId: string = null;
  qrCodeString: any = null;
  qrCodeFailCount = 0;
  qrCodeFailThreshold = 3;
  isForgetPassword: boolean;

  constructor(
    private _webclientLoginService: WebclientLoginService,
    private _inputValidationService: InputValidationService,
    private _loginComponent: LoginComponent,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _oauthService: OauthService,
    private _samlService: SamlService,
    private _tAndCService: TAndCService,
    private _baseRoutingService: BaseRoutingService,
    private route: ActivatedRoute,
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.isUseMobileLogin = config.GENERAL.IS_LOGIN_MODE_MOBILE ? '1' : '0';
      this.isDefaultUseMobile = config.GENERAL.IS_LOGIN_MODE_MOBILE;
      this.isShowDomainInput = this._teamnoteConfigService.config.GENERAL.IS_SHOW_DOAMIN_INPUT;
      this.focusInputBox();
    });
  }

  ngOnInit() {
    this.domain = this._teamnoteConfigService.config.HOST.DOMAIN;
    this.countryCode = this._teamnoteConfigService.config.GENERAL.DEFAULT_COUNTRY_CODE;
    // this.isForgetPassword = this.route.paramMap.get('isForgetPassword');
    const value = this.route.snapshot.queryParamMap.get('isForgetPassword');
    this.isForgetPassword = value ? value.toLocaleLowerCase() === 'true' : false;
    if (this.isForgetPassword) {
      this.registrationInit();
    }
    // // Clear all route related cookies when entering get-state
    // // (cannot directly (re)login, so we clear them and treat it as a new user session)
    // this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
    // this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);
    // this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA);

    const originalUserName = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
    const originalUserId = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID);

    let prevLoginTime = this._localStorageManagerService.getSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.LOGIN_TIME);
    if (!prevLoginTime && !this._teamnoteConfigService.config.WEBCLIENT.GENERAL.FORCE_LOGIN_NEW_TAB) {
      // If no force login on new tab, hardcode a prev login time to allow get state
      prevLoginTime = 1;
    }

    this.isAllowDomainInput = this._teamnoteConfigService.config.GENERAL.IS_CUSTOM_DOAMIN;

    const extAuthMethod = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);

    if (originalUserName && originalUserId && prevLoginTime && !extAuthMethod) {
      this.userName = originalUserName;
      this.apiGetState(this.userName);
    } else {
      this.getAreaCode();

      this.getLoginMethods();
    }
  }

  ngAfterViewInit() {
    this.focusInputBox();
  }

  focusInputBox(): void {
    if (this.isDomainInput) {
      this.domainInput.nativeElement.focus();
    }
    if (this.isUseMobileLogin === '1') {
      if (!this.phoneNumInput) {
        return;
      }
      this.phoneNumInput.nativeElement.focus();
    } else if (this.isUseMobileLogin === '0') {
      if (!this.userNameInput) {
        return;
      }
      this.userNameInput.nativeElement.focus();
    }
  }

  changeInputMode(value: string): void {
    this.isUseMobileLogin = value;
    setTimeout(() => {
      this.focusInputBox();
    }, 100);
  }

  getAreaCode() {
    this._webclientLoginService.getAreaCode(
      resp => {
        this.countryCode = resp['country-code'];
      },
      err => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  submit() {
    const isValid = this._inputValidationService.checkIsNotEmpty(this.userName);
    if (!isValid) {
      this._tnNotificationService.showCustomWarningByTranslateKey('LOGIN.GET_STATE.EMPTY_ERROR');
      this.focusInputBox();
      return;
    }

    // check if same user as previous session
    let userName = this.userName;
    const oldUser = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.USERNAME);
    if (oldUser !== userName) {
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME);
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD);
    }
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.USERNAME, this.userName.toString());
    if (this.isUseMobileLogin === '1') {
      userName = this.countryCode + '-' + this.userName;
    }

    const acceptTAndCFollowUpAction = () => {
      this.apiGetState(userName);
    };

    if (this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME) === userName) {
      acceptTAndCFollowUpAction();
      return;
    }

    // user is different from prev. session, clear user  preserved cookies
    this._webclientLoginService.removeUserPreserveCookies();

    this._tAndCService.openTAndCDialog(
      true,
      () => {
        acceptTAndCFollowUpAction();
      },
      (err) => {

      }
    );
  }

  apiGetState(userName: string): void {
    this._webclientLoginService.registrationGetState(
      userName,
      resp => {
        const response = this._webclientLoginService.handleGetStateSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleGetStateErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      }
    );
  }

  registrationInit() {
    console.log('this.isForgetPassword: ' + this.isForgetPassword);
    this._webclientLoginService.registrationInit(
      resp => {
        const response = this._webclientLoginService.handleRegistrationInitSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
        console.log('Resp: ' + JSON.stringify(resp));
      },
      err => {
        const response = this._webclientLoginService.getGeneralSystemErrorResposne();
        this.handleCustomLoginResponse(response);
      },
      this.isForgetPassword
    );
  }

  handleCustomLoginResponse(resp: CustomLoginResponse) {
    if (resp.nextApiCall === LOGIN_ACTIONS.API_REG_INIT) {
      this.registrationInit();
      return;
    }
    if (!resp.isSuccess) {
      this._tnNotificationService.showCustomErrorByTranslateKey(resp.errorMsg);
      if (this.loginMethods.length === 0) {
        this.getLoginMethods();
      }
    }
    if (resp.nextPageUrl) {
      this.toNextPage(resp.nextPageUrl);
      return;
    }
    this.focusInputBox();
  }

  toNextPage(nextPageUrl: string) {
    this._loginComponent.goToTargetPage(nextPageUrl);
  }

  showDomainInput(): void {
    this.isDomainInput = true;
    // this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN);
    this.isAllowTeamNoteLogin = false;
    this.loginMethods = [];
  }

  submitDomain(): void {
    if (!this.domain) {
      return;
    }
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN, this.domain);
    this.getLoginMethods();
  }

  // login methods
  getLoginMethods(): void {
    if (!this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN) ||
      this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN) === 'null') {
      this.showDomainInput();
      return;
    }

    this._webclientLoginService.getLoginMethods(
      (resp: LoginMethod[]) => {
        for (let i of resp) {
          if (i.auth_name === 'teamnote' && i.auth_type === 'teamnote') {
            this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.FORGETPW, i.config.forget_password && i.config.forget_password['type']);
            break;
          }
        }
        if (resp[0] && resp[0].config.cache_credential_domains) {
          this._localStorageManagerService.setCookiesByKey(
            TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.CREDDOMAIN,
            resp[0].config.cache_credential_domains.join(','));

        }
        this.isDomainInput = false;
        // handle "teamnote" method
        const tnMethods = _.filter(resp, function (m) {
          return m.auth_type === LoginMethodConstant.AUTH_TYPE.TEAMNOTE;
        });
        if (tnMethods.length > 0) {
          for (let i = 0; i < tnMethods.length; i++) {
            const method = tnMethods[i];
            if (method.config.login_method === 'phone_number') {
              this.isDefaultUseMobile = true;
              this.isUseMobileLogin = '1';
              // Teamnote Login methods exists, allow usage of normal teamnote login
              this.isAllowTeamNoteLogin = true;
            } else if (method.config.login_method === 'user_name') {
              this.isDefaultUseMobile = false;
              this.isUseMobileLogin = '0';
              // Teamnote Login methods exists, allow usage of normal teamnote login
              this.isAllowTeamNoteLogin = true;
            }
            if (method.config.login_method === 'qrcode') {
              this.isQRCodeLogin = true;
            }
          }
        }

        this.loginMethods = _.difference(resp, tnMethods);

        const handled = this.handleExternalAuthLogin();
        if (!handled) {
          this.checkIfRedirectIsNeeded();
        }

        if (this.isQRCodeLogin) {
          this.startQRCodeHandling();
        }
        this.focusInputBox();
      },
      (err) => {
        // Login methods are not available, allow usage of normal teamnote login
        // this.isAllowTeamNoteLogin = true;

        // and request domain input
        if (this.domain) {
          this._tnNotificationService.showCustomErrorByTranslateKey('LOGIN.GET_STATE.INVALID_DOMAIN');
          this.focusInputBox();
        }
        this.showDomainInput();
      }
    );
  }

  handleExternalAuthLogin(isValid?: boolean): boolean {
    const externalAuthType = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);

    switch (externalAuthType) {
      case LoginMethodConstant.AUTH_TYPE.OAUTH:
        this.initLoginFlowWithOauth();
        return true;
      case LoginMethodConstant.AUTH_TYPE.SAML:
        this.loginWithSaml();
        return true;
    }

    return false;
  }

  checkIfRedirectIsNeeded(): void {
    if (this._localStorageManagerService.getSessionStorageByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.SAML.REDIRECTED) === 'true') return;

    this._localStorageManagerService.setSessionStorageByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.SAML.REDIRECTED, 'true');
    const url = this._teamnoteConfigService.config.LOGIN.PWA_LOGIN_REDIRECT_URL;
    const ips = this._teamnoteConfigService.config.LOGIN.PWA_LOGIN_REDIRECT_IPS;
    if (url && ips.length > 0) {
      this._webclientLoginService.getAreaCode(function (response) {
        if (_.some(ips, (ip) => response['remote-ip'].startsWith(ip))) {
          window.location.replace(url);
        }
      }, null);
    }
  }

  clearExternalAuthLoginCookies(): void {
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME);
  }

  initLoginFlowWithOauth(): void {
    this._oauthService.getUserNameWithOAuthToken(
      (resp: OAuthMeResponse) => {
        if (!resp || !resp.user_name) {
          this.clearExternalAuthLoginCookies();
          return;
        }
        this.performNormalLoginFlowWithUserName(resp.user_name);
      },
      (err) => {
        this.clearExternalAuthLoginCookies();
        const response = this._webclientLoginService.getGeneralSystemErrorResposne();
        this.handleCustomLoginResponse(response);
      }
    );
  }

  loginWithSaml(): void {
    this._samlService.getUserNameWithSamlResponse(
      (resp: SamlMeResponse) => {
        if (!resp || !resp.user_name) {
          this.clearExternalAuthLoginCookies();
          this.checkIfRedirectIsNeeded();
          return;
        }
        this.performNormalLoginFlowWithUserName(resp.user_name);
      },
      (err) => {
        this.clearExternalAuthLoginCookies();
        const response = this._webclientLoginService.getGeneralSystemErrorResposne();
        this.handleCustomLoginResponse(response);
      }
    );
  }

  performNormalLoginFlowWithUserName(userName: string): void {
    this._webclientLoginService.registrationGetState(
      userName,
      (resp: GetStateResponse) => {
        console.error(resp);
        const response = this._webclientLoginService.handleGetStateSuccessResponse(resp);
        console.error(response);
        this.handleCustomLoginResponse(response);
      },
      (err) => {
        const response = this._webclientLoginService.handleGetStateErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      }
    );
  }

  // QR code
  startQRCodeHandling(): void {
    this.qrCodeFailCount = 0;
    this.updateQRCodeSessionId();
  }

  updateQRCodeSessionId(): void {
    if (this.sessionId !== null) {
      return;
    }
    this.sessionId = this.generateSessionId();
    const qrCode = {
      session_id: this.sessionId
    };
    this.qrCodeString = JSON.stringify(qrCode);

    this.tryToLoginWithQRCode();
  }

  generateSessionId(): string {
    const rand = function () {
      return Math.random().toString(36).substr(2); // remove `0.`
    };
    return rand() + rand();
  }

  tryToLoginWithQRCode(): void {
    const sessionToken = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);

    if (sessionToken) {
      return;
    }

    this._webclientLoginService.loginWithQRCode(
      this.sessionId,
      resp => {
        this._webclientLoginService.handleWebclientLoginSuccessResponse(resp);
        this._baseRoutingService.goToWebclientPage();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.sessionId = null;
          this.qrCodeFailCount++;
          if (this.qrCodeFailCount < this.qrCodeFailThreshold) {
            this.updateQRCodeSessionId();
          }
        }
      }
    );
  }

}
