<div class="tn-attachment">
  <div class="">

    <ng-container *ngIf="!data.isAttachment; else attachments">
      <tn-attachment-location
        [location]="data.location"
        *ngIf="!data.isAttachment">
      </tn-attachment-location>
    </ng-container>

    <ng-template #attachments>
      <ng-container [ngSwitch]="data.type">
        <tn-attachment-image
        [isDisableDownload]="data.isDisableDownload"
        [filename]="data.filename"
        [filenameMap]="data.filenameMap"
          [attachmentIds]="data.attachmentIds"
          [defaultFileId]="data.defaultFileId"
          [editImageCallbackWithImageUrlAndCaption]="data.editImageCallbackWithImageUrlAndCaption"
          [isDisableWatermark]="data.isDisableWatermark"
          *ngSwitchCase="ATTACHMENT_TYPE.IMAGE">
        </tn-attachment-image>

        <tn-attachment-video
            [filename]="data.filename"
          [attachmentId]="data.attachmentIds[0]"
          [isDisableWatermark]="data.isDisableWatermark"
          [isDisableDownload]="data.isDisableDownload"
          *ngSwitchCase="ATTACHMENT_TYPE.VIDEO">
        </tn-attachment-video>

        <tn-attachment-audio
          [attachmentId]="data.attachmentIds[0]"
          [attachment]="data.attachments[0]"
          *ngSwitchCase="ATTACHMENT_TYPE.AUDIO">
        </tn-attachment-audio>

        <tn-attachment-pdf
          [attachment]="data.attachments ? data.attachments[0] : null"
          [attachmentId]="data.attachmentIds[0]"
          [filename]="data.filename"
          [isDisableWatermark]="data.isDisableWatermark"
          [isDisableDownload]="data.isDisableDownload"
          [isChatMessage]="data.isChatMessage"
          (close)="close()"
          *ngSwitchDefault>
        </tn-attachment-pdf>
      </ng-container>
    </ng-template>

  </div>
</div>
