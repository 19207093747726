import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { LanguageConstant } from '../../constants/language.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { LanguageString } from '../../strings/string-helper.service';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
var addDays = require('date-fns/add_days');


@Injectable()
export class TranslateManagerService {

  currentTranslation$ = new BehaviorSubject<any>(null);

  currentI18nKey: string = "en";

  constructor(
    private _translate: TranslateService,
    private _localStorageManagerService: LocalStorageManagerService
  ) { 
    _translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTranslationSubject(event.translations);
    });
  }

  setDefaultLang(langKey: string) {
    this._translate.setDefaultLang(langKey);
    this.changeLang(langKey);
  }

  changeLang(langKey: string, isNeedLocalStroage?: boolean) {

    let langObj = null;
    _.each(LanguageConstant, (obj, key) => {
      if (obj.key == langKey) {
        langObj = obj;
      }
    });
    if (langObj) {
      this.currentI18nKey = langObj.i18n;
    }

    this._translate.use(langKey);

    if (isNeedLocalStroage) {
      let oneMonthFromNow = addDays(new Date(), 30);
      this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.LANGUAGE, langKey, oneMonthFromNow);
    }
  }

  getCurrentLangKey(): string {
    return this._translate.currentLang;
  }

  updateTranslationSubject(translation) {
    this.currentTranslation$.next(translation);
  }

  addTranslation(en: any, zhcht: any, zhchs: any, key?: string) {
    this._translate.setTranslation(LanguageConstant.EN.key, key ? {[key]:en} : en);
    this._translate.setTranslation(LanguageConstant.ZH_CHT.key, key ? {[key]:zhcht} : zhcht);
    this._translate.setTranslation(LanguageConstant.ZH_CHS.key, key ? {[key]:zhchs} : zhchs);
  }

  addTranslationByLanguageStringArr(strs: LanguageString[]): void {
    _.each(strs, (str) => {
      this._translate.setTranslation(str.key, str.strings);
    });
  }

  getTranslationByKey(key: string, callback: Function, param?: any) {
    this._translate.get(key, param).subscribe(t => callback(t));
  }

  getCurrentLangI18nKey(): string {
    return this.currentI18nKey;
  }



}
