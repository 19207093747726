export const ZhChtLoginStrings = {
  
    GET_STATE: {
      DOMAIN: {
        INPUT_TIP: "輸入網域",
        DOMAIN: "網域"
      },
      MOBILE: {
        INPUT_TIP: "輸入電話號碼",
        PHONE_NUMBER: "電話號碼"
      },
      USERNAME: {
        INPUT_TIP: "輸入用戶名稱",
        USERNAME: "用戶名稱"
      },
      EMPTY_ERROR: "請輸入登入資訊",
      FAIL_ERROR: "未註冊的帳戶",
      ACCOUNT_LOCKED: "你的帳戶已被鎖定",
      SUBMIT: "提交",
      QR_CODE: {
        BUTTON: "以二維條碼登入",
        TIP: `<p>
        在您的電腦上使用TeamNote Web Client:
        </p>
        <ol>
          <li>在您的手機開啟TeamNote</li>
          <li>(Android) 點撃更多... → 登入網頁客戶端<br>
            (iOS) 點撃更多... → 關於 → 登入網頁客戶端</li>
          <li>以您的手機讀取螢幕上的二維條碼</li>
        </ol>`
      },
      INVALID_DOMAIN: "網域不正確",
      FORBIDDEN_SELF_REGISTRATION: "您無法自行註冊",
      REMARKS: "備註:<br />用戶可在即時通訊系統中，查閱其他用戶的帳戶啟用狀態，以確定對方是否可接受訊息。<br />當你初次登入即時通訊系統後(包括經網頁版，或流動應用程式登入), 你的帳戶將會被啟用。"
    },
    OTP: {
      INPUT_TIP: "輸入一次性密碼 (OTP)",
      OTP: "OTP",
      FAIL_ERROR: "OTP不正確"
    },
    SECOND_FACTOR: {
      OTP_EMAIL: "一次性密碼 (OTP) 將經由電郵送出",
      OTP_SMS: "一次性密碼 (OTP) 將經由短訊送出",
      EMAIL: {
        TITLE: "電郵",
        INPUT_TIP: "輸入電郵"
      },
      MOBILE: {
        TITLE: "電話號碼",
        INPUT_TIP: "輸入電話號碼"
      },
      HKID: {
        TITLE: "香港身份證號碼",
        INPUT_TIP: "輸入香港身份證號碼"
      },
      FULLNAME: {
        TITLE: "全名",
        INPUT_TIP: "輸入你的全名"
      },
      LDAP_PW: {
        TITLE: "密碼",
        INPUT_TIP: "輸入密碼"
      },
      STAFFID: {
        TITLE: "員工編號",
        INPUT_TIP: "輸入員工編號"
      },
      STAFF_ID: {
        TITLE: "員工編號",
        INPUT_TIP: "輸入員工編號"
      },
      FAIL_ERROR: "輸入的資料不正確"
    },
    PASSWORD: {
      INPUT_TIP: "輸入密碼",
      NEW_PASSWORD_INPUT_TIP: "設定新密碼",
      CONFIRM: "確認新密碼",
      NEW_PASSWORD: "新密碼",
      PASSWORD: "密碼",
      SIGN_IN: "登入",
      FORGET_PASSWORD: "忘記密碼",
      SUBMIT: "提交",
      LOGIN_FAIL: "登入失敗，請留意是否更改過密碼及使用最新的正確密碼登入",
      LOGIN_FAIL_AUTH: "登入失敗",
      EMPTY_ERROR: "請輸入密碼",
      NOT_MATCH: "密碼不匹配",
      NOT_MATCH_POLICY: "密碼長度最少為{{NUM_CHAR}}個字，並且包括大小寫英文字母",
      DEVICE_TOKEN_WIPE: "登入失敗，設備令牌已被重置，請重試",
      MAX_REG_DEVICE: "你已經達到最大允許註冊裝置數目",
      EXPIRY_REMINDER: "你的密碼將於{{NUM_DAY}}日後到期，請更改密碼",
      PASSWORD_REUSED: "此密碼曾使用過，請輸入新的密碼",
      ACCOUNT_SUSPENDED: "帳號已被停權",
      SECURE_PASSWORD: "密碼",
    }
  
  }